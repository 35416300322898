import React from "react";
import logo from "./logo.jpeg";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="message">Website Coming Soon!</div>

        <table>
          <tr>
            {/* <a
              href="https://www.facebook.com/Curatably/"
              target="_blank"
              style={{ color: "black" }}
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebook}
                style={{ marginRight: "10px" }}
                size="xs"
              />
            </a> */}

            <a
              href="https://www.instagram.com/theapiary.co/"
              target="_blank"
              style={{ color: "black" }}
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                style={{ marginRight: "10px" }}
                size="lg"
              />
            </a>
          </tr>
        </table>
      </header>
    </div>
  );
}

export default App;
